@import '@aurora/shared-client/styles/mixins/_focus.pcss';

.lia-g-dropdown-toggle {
  display: flex;
  align-items: center;
  font-size: var(--lia-bs-font-size-sm);
  font-weight: var(--lia-font-weight-md);
  color: var(--lia-bs-body-color);
  border-color: var(--lia-bs-gray-500);
  border-radius: var(--lia-bs-border-radius);
  background-color: var(--lia-bs-white);
  padding-left: 10px;
  padding-right: 8px;

  &:not(.lia-g-no-arrow):after {
    display: inline-block;
    content: '';
    margin-left: 5px;
    width: var(--lia-icon-size-14);
    height: var(--lia-icon-size-14);
    background-color: var(--lia-bs-gray-900);
    mask-image: var(--lia-chevron-down-icon);
    mask-size: 14px;
    background-size: cover;
    vertical-align: middle;
  }

  &.lia-g-no-arrow {
    padding-right: 10px;
  }

  &.btn {
    &-link {
      padding: 0;
      border: none;
      background-color: inherit;

      &:hover,
      &:focus {
        color: var(--lia-bs-body-color);
      }
    }

    &-lg {
      font-size: var(--lia-bs-font-size-base);
    }
  }

  &:hover {
    box-shadow: none;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  @include default-focus-box-shadow();
}

.lia-g-faux-input {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 7px 10px;
  text-align: left;
  font-size: var(--lia-bs-input-btn-font-size);
  font-weight: var(--lia-bs-font-weight-base);
  line-height: 1.7142857;
  color: var(--lia-bs-body-color);
  background-color: var(--lia-bs-white);
  background-clip: padding-box;
  border: 1px solid var(--lia-bs-input-border-color);
  border-radius: var(--lia-bs-input-border-radius);
  transition: border-color var(--lia-timing-fast) var(--lia-timing-function),
    box-shadow var(--lia-timing-fast) var(--lia-timing-function);

  &:focus,
  &:focus-visible {
    border-color: var(--lia-bs-input-focus-border-color);
    outline: 0;
    box-shadow: var(--lia-bs-input-focus-box-shadow);
  }

  &.lia-g-faux-select:after {
    display: block;
    content: '';
    margin-left: auto;
    width: 16px;
    height: 17px;
    background-color: var(--lia-bs-gray-900);
    mask-image: var(--lia-select-arrow-icon);
    mask-size: 16px 17px;
    background-size: cover;
  }
}

.lia-g-faux-select-list {
  min-width: 100%;
}
